import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import {
  ActiveUserPage,
  DashboardPage,
  E404Page,
  AllocationDetailsPage,
  ForgotPasswordPage,
  AllocationsPage,
  LoginPage,
  PartnerDetailsPage,
  PartnerNewPage,
  PartnerUpdatePage,
  PartnersPage,
  ResetPasswordPage,
  RoleDetailsPage,
  RoleNewPage,
  RoleUpdatePage,
  RolesPage,
  SiteDetailsPage,
  SiteNewPage,
  SiteUpdatePage,
  SitesPage,
  UserDetailsPage,
  UserNewPage,
  UserUpdatePage,
  UsersPage,
  HardwareRegistrationsPage,
  HardwareRegistrationNewPage,
  HardwareDepletionsPage,
  HardwareDepletionNewPage,
  ProductsPage,
  ProductNewPage,
  ProductDetailsPage,
  ProductUpdatePage,
  ProfileUpdatePage,
  TrackingPage,
  TrackingDetailsPage,
  PartnerProfilePage,
  HardwareFillingListPage,
  HardwareFillingNewPage,
  HardwareFillingDetailsPage,
  AllocationSendPage,
  AllocationReceivePage,
  PlatformSitesPage,
  PlatformSiteNewPage,
  PlatformSiteDetailsPage,
  PlatformSiteUpdatePage,
  PlatformHardwarePage,
  PlatformHardwareDetailsPage,
  PlatformProductsPage,
  PlatformProductsDetailsPage,
  PartnerProfileUpdatePage,
  RegistrationDetailsPage,
  TrackingGroupDetailsPage,
  TrackingGroupUpdatePage,
  HardwareGroupNewPage,
  HardwareGroupUpdatePage,
  ProductGroupNewPage,
  ProductGroupDetailPage,
  ProductGroupUpdatePage,
  UpdateProductGroupPage,
  DepletionDetailsPage,
  TransfersPage,
  TransferNewPage,
  TransferDetailsPage,
  FaultReportDetailsPage,
  ConfigurationsPage,
  ConfigurationNewPage,
  ConfigurationDetailsPage,
  ApplyConfigurationPage,
  ConfigReportDetailsPage,
  TrackingUpdateConfigurationPage,
  E403Page,
  ConfigurationUpdatePage,
} from './pages';
import EmailLists from './pages/EmailLists/EmailLists';
import EmailListDetailsPage from './pages/EmailLists/EmailListDetails';
import EmailListUpdate from './pages/EmailLists/EmailListUpdate';
import E401Page from './pages/E401';
import NonRequireAuth from './NonRequireAuth';

export const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/active/:token',
    element: <ActiveUserPage />,
  },
  {
    path: '/reset-password/:token',
    element: <ResetPasswordPage />,
  },
  {
    path: '/',
    element: <RequireAuth />,
    children: [
      {
        path: '/dashboard',
        element: <DashboardPage />,
      },
      {
        path: '/users',
        element: <UsersPage />,
      },
      {
        path: '/users/new',
        element: <UserNewPage />,
      },
      {
        path: '/users/:id',
        element: <UserDetailsPage />,
      },
      {
        path: '/users/:id/edit',
        element: <UserUpdatePage />,
      },
      {
        path: '/platform/partners',
        element: <PartnersPage />,
      },
      {
        path: '/platform/partners/new',
        element: <PartnerNewPage />,
      },
      {
        path: '/platform/partners/:id',
        element: <PartnerDetailsPage />,
      },
      {
        path: '/platform/partners/:id/edit',
        element: <PartnerUpdatePage />,
      },
      {
        path: '/sites',
        element: <SitesPage />,
      },
      {
        path: '/sites/new',
        element: <SiteNewPage />,
      },
      {
        path: '/sites/:id',
        element: <SiteDetailsPage />,
      },
      {
        path: '/sites/:id/edit',
        element: <SiteUpdatePage />,
      },
      {
        path: '/roles',
        element: <RolesPage />,
      },
      {
        path: '/roles/new',
        element: <RoleNewPage />,
      },
      {
        path: '/roles/:id',
        element: <RoleDetailsPage />,
      },
      {
        path: '/roles/:id/edit',
        element: <RoleUpdatePage />,
      },
      {
        path: '/products',
        element: <ProductsPage />,
      },
      {
        path: '/products/new',
        element: <ProductNewPage />,
      },
      {
        path: '/products/:sku',
        element: <ProductDetailsPage />,
      },
      {
        path: '/products/:sku/edit',
        element: <ProductUpdatePage />,
      },
      {
        path: '/hardware-registrations',
        element: <HardwareRegistrationsPage />,
      },
      {
        path: '/hardware-registrations/new',
        element: <HardwareRegistrationNewPage />,
      },
      {
        path: '/hardware-registrations/:uuid',
        element: <RegistrationDetailsPage />,
      },
      {
        path: '/hardware-allocations',
        element: <AllocationsPage />,
      },
      {
        path: '/hardware-allocations/send',
        element: <AllocationSendPage />,
      },
      {
        path: '/hardware-allocations/receive',
        element: <AllocationReceivePage />,
      },
      {
        path: '/hardware-allocations/:uuid',
        element: <AllocationDetailsPage />,
      },
      {
        path: '/hardware-depletions',
        element: <HardwareDepletionsPage />,
      },
      {
        path: '/hardware-depletions/new',
        element: <HardwareDepletionNewPage />,
      },
      {
        path: '/hardware-depletions/:uuid',
        element: <DepletionDetailsPage />,
      },
      {
        path: '/profile',
        element: <ProfileUpdatePage />,
      },
      {
        path: '/tracking',
        element: <TrackingPage />,
      },
      {
        path: '/tracking/:serialCode',
        element: <TrackingDetailsPage />,
      },
      {
        path: '/tracking/groups/:code',
        element: <TrackingGroupDetailsPage />,
      },
      {
        path: '/tracking/groups/:code/edit',
        element: <TrackingGroupUpdatePage />,
      },
      {
        path: '/tracking/configurations/:serialCode/edit',
        element: <TrackingUpdateConfigurationPage />,
      },
      {
        path: '/partner-profile',
        element: <PartnerProfilePage />,
      },
      {
        path: '/partner-profile/edit',
        element: <PartnerProfileUpdatePage />,
      },
      {
        path: '/hardware-filling',
        element: <HardwareFillingListPage />,
      },
      {
        path: '/hardware-filling/new',
        element: <HardwareFillingNewPage />,
      },
      {
        path: '/hardware-filling/:uuid',
        element: <HardwareFillingDetailsPage />,
      },
      {
        path: '/platform/sites',
        element: <PlatformSitesPage />,
      },
      {
        path: '/platform/sites/new',
        element: <PlatformSiteNewPage />,
      },
      {
        path: '/platform/sites/:id',
        element: <PlatformSiteDetailsPage />,
      },
      {
        path: '/platform/sites/:id/edit',
        element: <PlatformSiteUpdatePage />,
      },
      {
        path: '/platform/hardware',
        element: <PlatformHardwarePage />,
      },
      {
        path: '/platform/hardware/:serialCode',
        element: <PlatformHardwareDetailsPage />,
      },
      {
        path: '/platform/products',
        element: <PlatformProductsPage />,
      },
      {
        path: '/platform/products/:sku',
        element: <PlatformProductsDetailsPage />,
      },
      {
        path: '/email-lists',
        element: <EmailLists />,
      },
      {
        path: '/email-lists/:id',
        element: <EmailListDetailsPage />,
      },
      { path: '/email-lists/:id/edit', element: <EmailListUpdate /> },
      {
        path: '/hardware-groups/new',
        element: <HardwareGroupNewPage />,
      },
      {
        path: '/hardware-groups/:code/edit',
        element: <HardwareGroupUpdatePage />,
      },
      {
        path: '/product-groups/new',
        element: <ProductGroupNewPage />,
      },
      {
        path: '/products/groups/:code',
        element: <ProductGroupDetailPage />,
      },
      {
        path: '/product-groups/:code/edit',
        element: <ProductGroupUpdatePage />,
      },
      {
        path: '/products/groups/:code/edit',
        element: <UpdateProductGroupPage />,
      },
      {
        path: '/transfers',
        element: <TransfersPage />,
      },
      {
        path: '/transfers/new',
        element: <TransferNewPage />,
      },
      {
        path: '/transfers/:code',
        element: <TransferDetailsPage />,
      },
      {
        path: '/fault-reports/:code',
        element: <FaultReportDetailsPage />,
      },
      {
        path: '/configurations',
        element: <ConfigurationsPage />,
      },
      {
        path: '/configurations/new',
        element: <ConfigurationNewPage />,
      },
      {
        path: '/configurations/:code',
        element: <ConfigurationDetailsPage />,
      },
      {
        path: '/configurations/apply',
        element: <ApplyConfigurationPage />,
      },
      {
        path: '/configurations/reports/:code',
        element: <ConfigReportDetailsPage />,
      },
      {
        path: '/configurations/:code/edit',
        element: <ConfigurationUpdatePage />,
      },
    ],
  },
  {
    path: '/',
    element: <NonRequireAuth />,
    children: [
      {
        path: '/*',
        element: <E404Page />,
      },
      {
        path: '/unauthorized',
        element: <E401Page />,
      },
      {
        path: '/not-allowed',
        element: <E403Page />,
      },
    ],
  },
]);

const MainApp: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default MainApp;
