import React, { Fragment, useMemo } from 'react';
import { DateTimeFormat, GetConfigUnit } from '../../utils/global';
import DetailsView from '../DetailsView';
import { Link, useOutletContext } from 'react-router-dom';
import { HardwareI } from '../../interfaces/hardware';
import { CurrentUserI } from '../../interfaces/auth';
import { Notice } from '../Notice';
import Text from '../Text';
import WarningIcon from '../../svgs/WarningIcon';
import Icon from '../Icon';
import { displayConfigMode } from '../../utils/configuration';
import DisplayLastUpdateApp from '../DisplayLastUpdate';
import RefreshButton from '../buttons/RefreshButton';
import SizeBox from '../SizeBox';

const RenderDescriptionWarningNotice = () => {
  return (
    <Fragment>
      <Text variant="bodyMdB" className="tw-block">
        Hardware Offline
      </Text>
      <Text variant="bodyMd" className="tw-inline-flex tw-w-full tw-items-center">
        To edit or apply preset to hardware’s configuration, please ensure the hardware is &nbsp;
        <span className="tw-text-success-500 tw-font-bold tw-inline-flex tw-gap-1 tw-items-center">
          <Icon name="icon-wifi" className="tw-mt-0.5" size={16} /> Online
        </span>
      </Text>
      <Text variant="bodyMd" className="!tw-block">
        For a SmartPour to be Online:
      </Text>
      <ul className="tw-m-0 tw-pl-6">
        <li>Turn on through the Power button on its interface</li>
        <li>Pair the hardware with the CircularONE app via an active Wifi connection.</li>
      </ul>
    </Fragment>
  );
};

type Props = {
  hardware?: HardwareI;
  refetchData: () => void;
};

const TrackingConfiguration: React.FC<Props> = ({ hardware, refetchData }) => {
  const currentUser: CurrentUserI = useOutletContext();

  const configuration = useMemo(() => {
    if (hardware?.type === 'smartpour') {
      const data = hardware.smartPour;

      return {
        updatedAt: data?.updatedAt,
        displayUnit: data?.configDisplayUnit,
        ecoToteMode: data?.configEcoToteMode,
        values: data?.configValues,
        code: hardware?.hardwareConfig?.code ?? undefined,
        name: hardware?.hardwareConfig?.name ?? undefined,
        productGroupCode: data?.configProductGroupCode,
        vsrMode: data?.configVsrMode,
        gradientKPlus: data?.configGradientKPlus,
        gradientKMinus: data?.configGradientKMinus,
      };
    }
  }, [hardware]);

  return (
    <>
      {hardware?.smartPour?.network !== null && hardware?.smartPour?.network !== 'online' && (
        <>
          <Notice message={<RenderDescriptionWarningNotice />} type="warning" icon={<WarningIcon />} closable={false} />
          <SizeBox height={16} />
        </>
      )}
      <div className="tw-flex tw-justify-between tw-items-center xs:tw-flex-col xs:tw-items-start">
        <DisplayLastUpdateApp />
        <RefreshButton doRefetch={refetchData} className="xs:tw-w-full xs:tw-mt-4" />
      </div>
      <DetailsView title="Configuration Settings" className="tw-mt-6 tw-shadow-primary">
        <div className="flex-row tw-mb-5">
          <DetailsView.Field label="Last Updated" value={DateTimeFormat(configuration?.updatedAt)} />
          {configuration?.code && (
            <DetailsView.Field
              label="Configuration Preset"
              value={
                <Link to={`/configurations/${configuration?.code}`}>
                  {configuration?.name} - {configuration?.code}
                </Link>
              }
            />
          )}
        </div>
        <div className="flex-row tw-mb-5">
          <DetailsView.Field
            label="Button 1"
            value={
              configuration?.values?.button1
                ? `${configuration?.values?.button1?.toFixed(2)} ${GetConfigUnit(configuration?.displayUnit ?? '')}`
                : 'Not Applicable'
            }
          />
          <DetailsView.Field
            label="Button 2"
            value={
              configuration?.values?.button2
                ? `${configuration?.values?.button2?.toFixed(2)} ${GetConfigUnit(configuration?.displayUnit ?? '')}`
                : 'Not Applicable'
            }
          />
        </div>
        <div className="flex-row tw-mb-5">
          <DetailsView.Field
            label="Button 3"
            value={
              configuration?.values?.button3
                ? `${configuration?.values?.button3?.toFixed(2)} ${GetConfigUnit(configuration?.displayUnit ?? '')}`
                : 'Not Applicable'
            }
          />
          <DetailsView.Field
            label="Button 4"
            value={
              configuration?.values?.button4 ? `${configuration?.values?.button4?.toFixed(2)} mL` : 'Not Applicable'
            }
          />
        </div>
        <div className="flex-row tw-mb-5">
          <DetailsView.Field
            label="Button 5"
            value={
              configuration?.values?.button5 ? `${configuration?.values?.button5?.toFixed(2)} mL` : 'Not Applicable'
            }
          />
          <div></div>
        </div>
      </DetailsView>
      {currentUser.partner.isEcoSpirit && (
        <DetailsView title="Extended Configuration Settings" className="tw-mt-4 tw-shadow-primary">
          <div className="flex-row">
            <DetailsView.Field label="Last Updated" value={DateTimeFormat(configuration?.updatedAt)} />
            {configuration?.code && (
              <DetailsView.Field
                label="Configuration Preset"
                value={
                  <Link to={`/configurations/${configuration?.code}`}>
                    {configuration?.name} - {configuration?.code}
                  </Link>
                }
              />
            )}
          </div>
          <DetailsView.Field label="Product Group Code" value={configuration?.productGroupCode} />
          <div className="flex-row">
            <DetailsView.Field label="ecoTOTE Mode" value={displayConfigMode(configuration?.ecoToteMode ?? '')} />
            <DetailsView.Field label="VSR Mode" value={displayConfigMode(configuration?.vsrMode ?? '')} />
          </div>
          <div className="flex-row">
            <DetailsView.Field label="Gradient K Plus" value={configuration?.gradientKPlus} />
            <DetailsView.Field label="Gradient K Minus" value={configuration?.gradientKMinus} />
          </div>
        </DetailsView>
      )}
    </>
  );
};

export default TrackingConfiguration;
