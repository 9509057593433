import React from 'react';
import { DateTimeFormat, GetConfigUnit } from '../../utils/global';
import DetailsView from '../DetailsView';
import Text from '../Text';
import { ConfigReportsI } from '../../interfaces/configuration';
import { handleDisplayHardwareType, handleDisplayIconByType } from '../../utils/hardware';

type Props = {
  data: ConfigReportsI;
};

const ConfigReportInformation: React.FC<Props> = ({ data }) => {
  return (
    <>
      <DetailsView title="Apply Configuration Details">
        <DetailsView.Field label="Date Created" value={DateTimeFormat(data?.createdAt)} />
        <div className="flex-row">
          <DetailsView.Field label="Configuration Name" value={data?.configName} />
          <DetailsView.Field label="Configuration ID" value={data?.configId} />
        </div>
        <div className="flex-row">
          <DetailsView.Field label="Total Number of Hardware" value={data?.hardwareList.length ?? 0} />
          <DetailsView.Field
            label="Hardware Type"
            value={
              <Text variant="labelMd" className="tw-flex tw-items-center tw-text-grey-900">
                {handleDisplayIconByType(data?.hardwareType)}
                {handleDisplayHardwareType(data?.hardwareType)}
              </Text>
            }
          />
        </div>
        {/* <DetailsView.Field label="Site" value={data?.site} /> */}
        <DetailsView.Field label="Remarks" value={data?.remarks} />
      </DetailsView>
      <DetailsView title="Configuration Settings" className="tw-mt-4">
        <div className="flex-row tw-mb-5">
          <DetailsView.Field
            label="Button 1"
            value={`${data?.values?.button1?.toFixed(2)} ${GetConfigUnit(data?.displayUnit)}`}
          />
          <DetailsView.Field
            label="Button 2"
            value={`${data?.values?.button2?.toFixed(2)} ${GetConfigUnit(data?.displayUnit)}`}
          />
        </div>
        <div className="flex-row tw-mb-5">
          <DetailsView.Field
            label="Button 3"
            value={`${data?.values?.button3?.toFixed(2)} ${GetConfigUnit(data?.displayUnit)}`}
          />
          <DetailsView.Field label="Button 4" value={`${data?.values?.button4?.toFixed(2)} mL`} />
        </div>
        <div className="flex-row tw-mb-5">
          <DetailsView.Field label="Button 5" value={`${data?.values?.button5?.toFixed(2)} mL`} />
          <div></div>
        </div>
      </DetailsView>
    </>
  );
};

export default ConfigReportInformation;
